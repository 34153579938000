<template>
  <div>
    <svg
      v-if="getScreenSize == false"
      class="responsive-logo"
      width="298"
      height="130"
      viewBox="0 0 298 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_579_9114)">
        <path
          d="M33.4476 129.293C33.4476 129.505 33.3746 129.647 33.2286 129.788C33.0826 129.929 32.9366 130 32.7176 130H30.0898C29.8708 130 29.7248 129.929 29.5788 129.788C29.4328 129.647 29.3598 129.505 29.3598 129.293V122.863C29.3598 122.651 29.2868 122.51 29.1408 122.369C28.9949 122.228 28.8489 122.157 28.6299 122.157H14.6877C14.4687 122.157 14.3227 122.086 14.1767 121.945C14.0307 121.804 13.9577 121.662 13.9577 121.45V118.977C13.9577 118.765 14.0307 118.624 14.1767 118.483C14.3227 118.341 14.4687 118.271 14.6877 118.271H28.7029C28.9219 118.271 29.0679 118.2 29.2138 118.059C29.3598 117.917 29.4328 117.776 29.4328 117.564V107.177C29.4328 106.965 29.3598 106.824 29.2138 106.683C29.0679 106.541 28.9219 106.471 28.7029 106.471H14.8337C13.4467 106.471 12.1328 106.753 10.8919 107.248C9.72396 107.743 8.62903 108.449 7.68008 109.368C6.73113 110.286 6.00118 111.346 5.49021 112.477C4.97924 113.678 4.68725 114.95 4.68725 116.292V129.293C4.68725 129.505 4.61426 129.647 4.46826 129.788C4.32227 129.929 4.17628 130 3.95729 130H1.32945C1.11046 130 0.964466 129.929 0.818475 129.788C0.672483 129.647 0.599487 129.505 0.599487 129.293V116.292C0.599487 115.091 0.745479 113.819 1.11046 112.689C1.47544 111.558 1.91341 110.428 2.57037 109.438C3.15434 108.449 3.95729 107.46 4.76025 106.683C5.6362 105.835 6.58514 105.128 7.68008 104.492C8.77502 103.856 9.86995 103.432 11.0379 103.079C12.2788 102.655 13.5927 102.514 14.8337 102.514H32.7176C32.9366 102.514 33.0826 102.584 33.2286 102.726C33.3746 102.867 33.4476 103.008 33.4476 103.22V129.293Z"
          fill="white"
        />
        <path
          d="M74.6174 129.293C74.6174 129.505 74.5444 129.647 74.3984 129.788C74.2524 129.929 74.1065 130 73.8875 130H56.1495C54.2516 130 52.4267 129.647 50.6748 128.94C49.0689 128.233 47.682 127.244 46.514 126.043C45.3461 124.771 44.3972 123.287 43.8132 121.662C43.1562 119.966 42.8643 118.129 42.8643 116.292C42.8643 114.455 43.1562 112.689 43.8132 110.993C44.3972 109.368 45.3461 107.884 46.514 106.612C47.682 105.34 49.0689 104.351 50.6748 103.644C52.4267 102.867 54.2516 102.514 56.1495 102.514H73.8875C74.1065 102.514 74.2524 102.584 74.3984 102.726C74.5444 102.867 74.6174 103.008 74.6174 103.22V105.693C74.6174 105.905 74.5444 106.047 74.3984 106.188C74.2524 106.329 74.1065 106.4 73.8875 106.4H56.1495C54.8356 106.4 53.5216 106.683 52.2807 107.177C51.1858 107.672 50.1638 108.378 49.4339 109.297C48.6309 110.216 48.047 111.275 47.609 112.406C47.171 113.607 46.952 114.95 47.025 116.222C47.025 117.493 47.244 118.836 47.609 120.037C48.047 121.168 48.6309 122.228 49.4339 123.146C50.2368 124.065 51.1858 124.771 52.2807 125.266C53.4487 125.76 54.7626 126.043 56.1495 126.043H73.8875C74.1065 126.043 74.2524 126.114 74.3984 126.255C74.5444 126.396 74.6174 126.538 74.6174 126.75V129.293Z"
          fill="white"
        />
        <path
          d="M115.86 129.293C115.86 129.505 115.787 129.646 115.641 129.788C115.495 129.929 115.349 130 115.13 130H112.575C112.356 130 112.21 129.929 112.064 129.788C111.918 129.646 111.845 129.505 111.845 129.293V122.863C111.845 122.651 111.772 122.51 111.626 122.369C111.48 122.227 111.334 122.157 111.115 122.157H86.6618C86.4428 122.157 86.2968 122.086 86.1508 121.945C86.0048 121.803 85.9318 121.662 85.9318 121.45V118.977C85.9318 118.765 86.0048 118.624 86.1508 118.482C86.2968 118.341 86.4428 118.27 86.6618 118.27H111.042C111.261 118.27 111.407 118.2 111.553 118.058C111.699 117.917 111.772 117.776 111.772 117.564V107.177C111.772 106.965 111.699 106.824 111.553 106.682C111.407 106.541 111.261 106.47 111.042 106.47H97.1732C95.8592 106.47 94.4723 106.753 93.2314 107.248C91.9905 107.742 90.8955 108.449 90.0196 109.367C89.0706 110.286 88.3407 111.346 87.8297 112.476C87.3187 113.678 87.0267 114.949 87.0267 116.292V129.293C87.0267 129.505 86.9537 129.646 86.8077 129.788C86.6618 129.929 86.5158 130 86.2968 130H83.7419C83.5229 130 83.3769 129.929 83.2309 129.788C83.085 129.646 83.012 129.505 83.012 129.293V116.221C83.012 115.02 83.158 113.748 83.5229 112.547C84.1799 110.215 85.4208 108.166 87.1727 106.47C88.9246 104.775 91.0415 103.573 93.4504 102.937C94.6913 102.584 95.9322 102.443 97.2462 102.443H115.203C115.422 102.443 115.568 102.514 115.714 102.655C115.86 102.796 115.933 102.937 115.933 103.149L115.86 129.293Z"
          fill="white"
        />
        <path
          d="M159.803 112.265C159.803 113.607 159.511 114.879 159 116.08C157.978 118.412 156.008 120.32 153.599 121.309C152.358 121.804 150.971 122.086 149.657 122.086H139.948C139.729 122.086 139.583 122.016 139.437 121.874C139.291 121.733 139.218 121.592 139.218 121.38V118.977C139.218 118.765 139.291 118.624 139.437 118.483C139.583 118.341 139.729 118.271 139.948 118.271H149.657C150.46 118.271 151.263 118.129 152.066 117.847C153.526 117.211 154.694 116.08 155.278 114.667C155.57 113.96 155.789 113.113 155.789 112.335C155.789 111.558 155.643 110.781 155.278 110.004C154.986 109.297 154.548 108.661 153.964 108.166C153.38 107.601 152.723 107.177 151.993 106.895C151.263 106.612 150.46 106.471 149.657 106.471H131.773C131.554 106.471 131.408 106.541 131.262 106.683C131.116 106.824 131.043 106.965 131.043 107.177V129.293C131.043 129.505 130.97 129.647 130.824 129.788C130.678 129.929 130.532 130 130.313 130H127.758C127.539 130 127.393 129.929 127.247 129.788C127.101 129.647 127.028 129.505 127.028 129.293V103.22C127.028 103.008 127.101 102.867 127.247 102.726C127.393 102.584 127.539 102.514 127.758 102.514H149.73C151.117 102.514 152.431 102.796 153.672 103.291C156.081 104.28 158.051 106.188 159.073 108.52C159.584 109.721 159.876 110.993 159.803 112.265Z"
          fill="white"
        />
        <path
          d="M197.688 129.293C197.688 129.505 197.615 129.647 197.469 129.788C197.323 129.929 197.177 130 196.958 130H168.928C168.709 130 168.563 129.929 168.417 129.788C168.271 129.647 168.198 129.505 168.198 129.293V103.22C168.198 103.008 168.271 102.867 168.417 102.726C168.563 102.584 168.709 102.514 168.928 102.514H196.958C197.177 102.514 197.323 102.584 197.469 102.726C197.615 102.867 197.688 103.008 197.688 103.22V105.693C197.688 105.905 197.615 106.047 197.469 106.188C197.323 106.329 197.177 106.4 196.958 106.4H173.015C172.796 106.4 172.65 106.471 172.505 106.612C172.359 106.753 172.286 106.895 172.286 107.107V125.336C172.286 125.548 172.359 125.69 172.505 125.831C172.65 125.972 172.796 126.043 173.015 126.043H196.958C197.177 126.043 197.323 126.114 197.469 126.255C197.615 126.396 197.688 126.538 197.688 126.75V129.293ZM194.257 117.493C194.257 117.705 194.184 117.847 194.038 117.988C193.892 118.129 193.746 118.2 193.527 118.2H171.191C170.972 118.2 170.826 118.129 170.68 117.988C170.534 117.847 170.461 117.705 170.461 117.493V115.02C170.461 114.808 170.534 114.667 170.68 114.526C170.826 114.384 170.972 114.314 171.191 114.314H193.454C193.673 114.314 193.819 114.384 193.965 114.526C194.111 114.667 194.184 114.808 194.184 115.02L194.257 117.493Z"
          fill="white"
        />
        <path
          d="M240.537 116.222C240.537 118.059 240.245 119.896 239.588 121.592C239.004 123.217 238.055 124.701 236.887 125.972C235.646 127.244 234.186 128.304 232.507 128.94C230.828 129.647 229.076 130 227.252 129.929H207.324C207.105 129.929 206.959 129.859 206.813 129.717C206.667 129.576 206.594 129.435 206.594 129.223V126.75C206.594 126.538 206.667 126.396 206.813 126.255C206.959 126.114 207.105 126.043 207.324 126.043H227.325C228.638 126.043 229.952 125.831 231.12 125.266C232.215 124.771 233.237 124.065 233.967 123.146C234.77 122.228 235.354 121.168 235.792 120.037C236.23 118.836 236.449 117.493 236.449 116.222C236.449 114.95 236.23 113.607 235.792 112.406C235.427 111.275 234.77 110.216 233.967 109.297C233.164 108.378 232.215 107.672 231.12 107.177C229.952 106.612 228.638 106.4 227.325 106.4H211.411C211.192 106.4 211.046 106.471 210.9 106.612C210.755 106.753 210.681 106.895 210.681 107.107V118.907C210.681 119.119 210.609 119.26 210.463 119.401C210.317 119.542 210.171 119.613 209.952 119.613H207.324C207.105 119.613 206.959 119.542 206.813 119.401C206.667 119.26 206.594 119.119 206.594 118.907V103.22C206.594 103.008 206.667 102.867 206.813 102.726C206.959 102.584 207.105 102.514 207.324 102.514H227.252C229.076 102.514 230.828 102.867 232.507 103.503C234.186 104.21 235.646 105.199 236.887 106.471C238.055 107.743 239.004 109.226 239.588 110.851C240.245 112.618 240.61 114.384 240.537 116.222Z"
          fill="white"
        />
        <path
          d="M253.968 129.293C253.968 129.505 253.895 129.647 253.749 129.788C253.603 129.929 253.384 130 253.165 130H250.318C250.099 130 249.88 129.929 249.734 129.788C249.588 129.647 249.515 129.505 249.515 129.293V103.22C249.515 103.008 249.588 102.867 249.734 102.726C249.88 102.584 250.099 102.514 250.318 102.514H253.165C253.384 102.514 253.603 102.584 253.749 102.726C253.895 102.867 253.968 103.008 253.968 103.22V129.293Z"
          fill="white"
        />
        <path
          d="M297.254 129.293C297.254 129.505 297.181 129.647 297.035 129.788C296.889 129.929 296.743 130 296.524 130H293.97C293.751 130 293.605 129.929 293.459 129.788C293.313 129.647 293.24 129.505 293.24 129.293V122.863C293.24 122.651 293.167 122.51 293.021 122.369C292.875 122.228 292.729 122.157 292.51 122.157H278.494C278.275 122.157 278.129 122.086 277.983 121.945C277.837 121.804 277.764 121.662 277.764 121.45V118.977C277.764 118.765 277.837 118.624 277.983 118.483C278.129 118.341 278.275 118.271 278.494 118.271H292.437C292.656 118.271 292.802 118.2 292.948 118.059C293.094 117.917 293.167 117.776 293.167 117.564V107.177C293.167 106.965 293.094 106.824 292.948 106.683C292.802 106.541 292.656 106.471 292.437 106.471H278.64C277.254 106.471 275.94 106.753 274.699 107.248C273.458 107.743 272.363 108.449 271.487 109.368C270.538 110.286 269.808 111.346 269.297 112.477C268.786 113.678 268.494 114.95 268.494 116.292V129.293C268.494 129.505 268.421 129.647 268.275 129.788C268.129 129.929 267.983 130 267.764 130H265.209C264.99 130 264.844 129.929 264.698 129.788C264.552 129.647 264.479 129.505 264.479 129.293V116.292C264.479 113.89 265.136 111.487 266.377 109.368C266.961 108.378 267.764 107.389 268.567 106.612C269.443 105.764 270.392 105.057 271.487 104.422C272.582 103.786 273.677 103.362 274.845 103.008C276.086 102.655 277.327 102.514 278.64 102.514H296.524C296.743 102.514 296.889 102.584 297.035 102.726C297.181 102.867 297.254 103.008 297.254 103.22V129.293Z"
          fill="white"
        />
      </g>
      <path
        d="M183.198 81.2714C181.241 81.2898 179.347 80.5789 177.886 79.2771C175.43 76.8823 173.149 74.3154 171.059 71.596C165.275 64.4787 158.081 55.6208 149.465 55.6067C140.89 55.6067 133.598 64.4999 127.744 71.6454C125.651 74.3448 123.372 76.895 120.924 79.2771C119.43 80.5758 117.513 81.2834 115.534 81.2665C113.555 81.2496 111.651 80.5095 110.18 79.1855C107.672 76.6768 107.235 73.2309 108.982 69.7286C109.278 69.1578 137.037 15.0803 141.32 6.51129C142.532 4.09422 144.547 0.0704685 149.345 0H149.472C154.27 0.0704685 156.285 4.09422 157.489 6.51129C161.773 15.0803 189.539 69.1578 189.814 69.7004C191.575 73.2239 191.138 76.6768 188.63 79.1855C187.156 80.5562 185.21 81.3037 183.198 81.2714ZM149.458 50.6739C160.427 50.6739 168.445 60.5395 174.891 68.4884C176.825 71.0049 178.93 73.3853 181.19 75.6128C181.749 76.0566 182.438 76.3051 183.152 76.3203C183.866 76.3356 184.565 76.1168 185.142 75.6973C185.812 74.9926 186.446 73.999 185.417 71.9343C185.15 71.4128 157.377 17.3071 153.079 8.71696C151.501 5.55292 150.627 4.9328 149.408 4.9328C148.189 4.9328 147.295 5.55292 145.738 8.71696C141.44 17.3071 113.674 71.434 113.392 71.9554C112.371 73.999 113.005 75.0279 113.674 75.6973C114.253 76.1174 114.953 76.3365 115.668 76.3212C116.383 76.3059 117.073 76.0571 117.634 75.6128C119.906 73.3937 122.024 71.0229 123.975 68.5166C130.456 60.603 138.523 50.7585 149.338 50.6739H149.465H149.458Z"
        fill="url(#paint0_linear_579_9114)"
      />
      <ellipse
        cx="149.422"
        cy="74.2586"
        rx="7.31198"
        ry="7.31352"
        fill="url(#paint1_linear_579_9114)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_579_9114"
          x1="149.408"
          y1="1.77581"
          x2="149.408"
          y2="79.4956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FD6B51" />
          <stop
            offset="0.53"
            stop-color="#FF8718"
          />
          <stop
            offset="1"
            stop-color="#FFB018"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_579_9114"
          x1="149.422"
          y1="66.9451"
          x2="149.422"
          y2="81.5721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8BB8FF" />
          <stop
            offset="1"
            stop-color="#4C70DF"
          />
          <stop
            offset="1"
            stop-color="#4C70DF"
          />
        </linearGradient>
        <clipPath id="clip0_579_9114">
          <rect
            width="296.801"
            height="27.4862"
            fill="white"
            transform="translate(0.599487 102.514)"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      v-if="getScreenSize"
      class="responsive-logo"
      width="460"
      height="75"
      viewBox="0 0 460 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.3138 74.9836C70.3971 75.0012 68.5423 74.3279 67.1102 73.0947C64.7754 70.8952 62.6074 68.5357 60.623 66.0346C55.1853 59.5453 48.412 51.4862 40.3811 51.4778C32.3936 51.4778 25.5336 59.5705 20.0264 66.0766C18.0353 68.5592 15.8677 70.9043 13.5393 73.0947C12.0789 74.333 10.2006 75.0107 8.25915 74.9999C6.31774 74.989 4.44762 74.2904 3.00204 73.036C0.530338 70.6266 0.105379 67.3526 1.81389 64.0366C2.09141 63.5161 28.3782 13.9608 32.437 6.11152C33.5297 3.9876 35.5591 0.0671595 40.2423 0H40.3551C45.073 0.0671595 47.0937 3.9876 48.1605 6.11152C52.2193 13.9608 78.5147 63.5161 78.7749 64.0366C80.5094 67.3946 80.0671 70.6518 77.6041 73.036C76.1604 74.3124 74.2688 75.0088 72.3138 74.9836ZM40.3724 46.4408C50.8837 46.4408 58.5243 55.5409 64.6558 62.8529C66.4762 65.1511 68.4583 67.3249 70.5879 69.359C71.0662 69.7222 71.6527 69.9265 72.2604 69.9416C72.8681 69.9567 73.4646 69.7819 73.9616 69.4429C74.5339 68.8973 75.0717 68.0326 74.1697 66.2864C73.9269 65.8163 47.6141 16.2358 43.564 8.39494C42.133 5.59103 41.3611 5.03696 40.3291 5.03696C39.297 5.03696 38.5252 5.59103 37.0855 8.39494C33.018 16.2694 6.73127 65.8499 6.45375 66.32C5.58649 68.041 6.10684 68.9057 6.67924 69.4513C7.1777 69.7865 7.77356 69.959 8.38026 69.9439C8.98696 69.9288 9.57288 69.7268 10.0529 69.3674C12.1825 67.3403 14.1701 65.178 16.0023 62.8949C22.1772 55.5997 29.8785 46.5247 40.2857 46.4492H40.4071L40.3724 46.4408Z"
        fill="url(#paint0_linear_2721_5416)"
      />
      <ellipse
        cx="40.2428"
        cy="67.58"
        rx="7.37174"
        ry="7.1357"
        fill="url(#paint1_linear_2721_5416)"
      />
      <g clip-path="url(#clip0_2721_5416)">
        <g clip-path="url(#clip1_2721_5416)">
          <path
            d="M145.615 53.643C145.615 53.8948 145.529 54.0627 145.355 54.2306C145.182 54.3985 145.008 54.4825 144.748 54.4825H141.626C141.366 54.4825 141.192 54.3985 141.019 54.2306C140.845 54.0627 140.759 53.8948 140.759 53.643V46.0036C140.759 45.7517 140.672 45.5838 140.498 45.4159C140.325 45.248 140.151 45.1641 139.891 45.1641H123.327C123.066 45.1641 122.893 45.0801 122.719 44.9123C122.546 44.7444 122.459 44.5765 122.459 44.3246V41.3864C122.459 41.1345 122.546 40.9666 122.719 40.7987C122.893 40.6308 123.066 40.5469 123.327 40.5469H139.978C140.238 40.5469 140.412 40.4629 140.585 40.295C140.759 40.1271 140.845 39.9592 140.845 39.7074V27.3668C140.845 27.115 140.759 26.9471 140.585 26.7792C140.412 26.6113 140.238 26.5273 139.978 26.5273H123.5C121.852 26.5273 120.291 26.8631 118.817 27.4508C117.429 28.0384 116.128 28.8779 115.001 29.9693C113.873 31.0606 113.006 32.3198 112.399 33.663C111.792 35.0902 111.445 36.6013 111.445 38.1963V53.643C111.445 53.8948 111.358 54.0627 111.185 54.2306C111.011 54.3985 110.838 54.4825 110.578 54.4825H107.456C107.195 54.4825 107.022 54.3985 106.849 54.2306C106.675 54.0627 106.588 53.8948 106.588 53.643V38.1963C106.588 36.7692 106.762 35.2581 107.195 33.9149C107.629 32.5717 108.149 31.2285 108.93 30.0532C109.624 28.8779 110.578 27.7026 111.532 26.7792C112.572 25.7718 113.7 24.9323 115.001 24.1768C116.302 23.4212 117.603 22.9175 118.99 22.4978C120.465 21.9941 122.026 21.8262 123.5 21.8262H144.748C145.008 21.8262 145.182 21.9101 145.355 22.078C145.529 22.2459 145.615 22.4138 145.615 22.6657V53.643Z"
            fill="white"
          />
          <path
            d="M194.529 53.643C194.529 53.8948 194.442 54.0627 194.269 54.2306C194.095 54.3985 193.922 54.4825 193.662 54.4825H172.587C170.332 54.4825 168.164 54.0627 166.083 53.2232C164.175 52.3837 162.527 51.2085 161.139 49.7813C159.752 48.2702 158.624 46.5073 157.931 44.5765C157.15 42.5617 156.803 40.379 156.803 38.1963C156.803 36.0136 157.15 33.9149 157.931 31.9001C158.624 29.9693 159.752 28.2063 161.139 26.6952C162.527 25.1841 164.175 24.0089 166.083 23.1694C168.164 22.2459 170.332 21.8262 172.587 21.8262H193.662C193.922 21.8262 194.095 21.9101 194.269 22.078C194.442 22.2459 194.529 22.4138 194.529 22.6657V25.6039C194.529 25.8557 194.442 26.0236 194.269 26.1915C194.095 26.3594 193.922 26.4434 193.662 26.4434H172.587C171.026 26.4434 169.465 26.7792 167.991 27.3668C166.69 27.9545 165.476 28.794 164.608 29.8853C163.654 30.9767 162.961 32.2359 162.44 33.5791C161.92 35.0062 161.66 36.6013 161.747 38.1124C161.747 39.6234 162.007 41.2185 162.44 42.6456C162.961 43.9888 163.654 45.248 164.608 46.3394C165.562 47.4307 166.69 48.2702 167.991 48.8579C169.378 49.4455 170.939 49.7813 172.587 49.7813H193.662C193.922 49.7813 194.095 49.8653 194.269 50.0332C194.442 50.2011 194.529 50.369 194.529 50.6208V53.643Z"
            fill="white"
          />
          <path
            d="M243.529 53.6425C243.529 53.8943 243.443 54.0622 243.269 54.2301C243.096 54.398 242.922 54.482 242.662 54.482H239.627C239.367 54.482 239.193 54.398 239.02 54.2301C238.846 54.0622 238.76 53.8943 238.76 53.6425V46.0031C238.76 45.7512 238.673 45.5833 238.499 45.4154C238.326 45.2475 238.152 45.1636 237.892 45.1636H208.839C208.579 45.1636 208.405 45.0796 208.232 44.9117C208.058 44.7438 207.972 44.5759 207.972 44.3241V41.3859C207.972 41.134 208.058 40.9661 208.232 40.7982C208.405 40.6303 208.579 40.5464 208.839 40.5464H237.806C238.066 40.5464 238.239 40.4624 238.413 40.2945C238.586 40.1266 238.673 39.9587 238.673 39.7069V27.3663C238.673 27.1145 238.586 26.9466 238.413 26.7787C238.239 26.6108 238.066 26.5268 237.806 26.5268H221.327C219.766 26.5268 218.119 26.8626 216.644 27.4503C215.17 28.0379 213.869 28.8774 212.828 29.9687C211.701 31.0601 210.834 32.3193 210.227 33.6625C209.619 35.0896 209.273 36.6007 209.273 38.1958V53.6425C209.273 53.8943 209.186 54.0622 209.012 54.2301C208.839 54.398 208.665 54.482 208.405 54.482H205.37C205.11 54.482 204.936 54.398 204.763 54.2301C204.589 54.0622 204.503 53.8943 204.503 53.6425V38.1118C204.503 36.6847 204.676 35.1736 205.11 33.7465C205.89 30.9761 207.365 28.5416 209.446 26.5268C211.527 24.512 214.042 23.0849 216.904 22.3293C218.379 21.9096 219.853 21.7417 221.414 21.7417H242.749C243.009 21.7417 243.183 21.8256 243.356 21.9935C243.529 22.1614 243.616 22.3293 243.616 22.5812L243.529 53.6425Z"
            fill="white"
          />
          <path
            d="M295.739 33.4112C295.739 35.0062 295.392 36.5173 294.785 37.9445C293.57 40.7148 291.229 42.9814 288.367 44.1567C286.893 44.7444 285.245 45.0801 283.684 45.0801H272.149C271.889 45.0801 271.715 44.9962 271.542 44.8283C271.368 44.6604 271.282 44.4925 271.282 44.2407V41.3864C271.282 41.1345 271.368 40.9666 271.542 40.7987C271.715 40.6308 271.889 40.5469 272.149 40.5469H283.684C284.638 40.5469 285.592 40.379 286.546 40.0432C288.28 39.2876 289.668 37.9445 290.362 36.2655C290.708 35.426 290.969 34.4186 290.969 33.4951C290.969 32.5717 290.795 31.6482 290.362 30.7248C290.015 29.8853 289.494 29.1298 288.8 28.5421C288.107 27.8705 287.326 27.3668 286.459 27.031C285.592 26.6952 284.638 26.5273 283.684 26.5273H262.436C262.175 26.5273 262.002 26.6113 261.829 26.7792C261.655 26.9471 261.568 27.115 261.568 27.3668V53.643C261.568 53.8948 261.482 54.0627 261.308 54.2306C261.135 54.3985 260.961 54.4825 260.701 54.4825H257.666C257.406 54.4825 257.232 54.3985 257.059 54.2306C256.885 54.0627 256.798 53.8948 256.798 53.643V22.6657C256.798 22.4138 256.885 22.2459 257.059 22.078C257.232 21.9101 257.406 21.8262 257.666 21.8262H283.77C285.418 21.8262 286.979 22.162 288.454 22.7496C291.316 23.9249 293.657 26.1915 294.871 28.9619C295.478 30.389 295.825 31.9001 295.739 33.4112Z"
            fill="white"
          />
          <path
            d="M340.749 53.643C340.749 53.8948 340.663 54.0627 340.489 54.2306C340.316 54.3985 340.142 54.4825 339.882 54.4825H306.579C306.319 54.4825 306.146 54.3985 305.972 54.2306C305.799 54.0627 305.712 53.8948 305.712 53.643V22.6657C305.712 22.4138 305.799 22.2459 305.972 22.078C306.146 21.9101 306.319 21.8262 306.579 21.8262H339.882C340.142 21.8262 340.316 21.9101 340.489 22.078C340.663 22.2459 340.749 22.4138 340.749 22.6657V25.6039C340.749 25.8557 340.663 26.0236 340.489 26.1915C340.316 26.3594 340.142 26.4434 339.882 26.4434H311.436C311.176 26.4434 311.002 26.5273 310.829 26.6952C310.655 26.8631 310.569 27.031 310.569 27.2829V48.9418C310.569 49.1937 310.655 49.3616 310.829 49.5295C311.002 49.6974 311.176 49.7813 311.436 49.7813H339.882C340.142 49.7813 340.316 49.8653 340.489 50.0332C340.663 50.2011 340.749 50.369 340.749 50.6208V53.643ZM336.673 39.6234C336.673 39.8753 336.587 40.0432 336.413 40.2111C336.24 40.379 336.066 40.4629 335.806 40.4629H309.268C309.008 40.4629 308.834 40.379 308.661 40.2111C308.487 40.0432 308.401 39.8753 308.401 39.6234V36.6852C308.401 36.4334 308.487 36.2655 308.661 36.0976C308.834 35.9297 309.008 35.8457 309.268 35.8457H335.719C335.98 35.8457 336.153 35.9297 336.326 36.0976C336.5 36.2655 336.587 36.4334 336.587 36.6852L336.673 39.6234Z"
            fill="white"
          />
          <path
            d="M391.658 38.1123C391.658 40.295 391.311 42.4777 390.531 44.4925C389.837 46.4233 388.709 48.1863 387.322 49.6974C385.847 51.2085 384.113 52.4677 382.118 53.2232C380.124 54.0627 378.042 54.4825 375.874 54.3985H352.198C351.937 54.3985 351.764 54.3146 351.59 54.1467C351.417 53.9788 351.33 53.8109 351.33 53.559V50.6208C351.33 50.369 351.417 50.2011 351.59 50.0332C351.764 49.8653 351.937 49.7813 352.198 49.7813H375.961C377.522 49.7813 379.083 49.5295 380.47 48.8579C381.771 48.2702 382.985 47.4307 383.853 46.3394C384.807 45.248 385.501 43.9888 386.021 42.6456C386.541 41.2185 386.801 39.6234 386.801 38.1123C386.801 36.6013 386.541 35.0062 386.021 33.5791C385.587 32.2359 384.807 30.9767 383.853 29.8853C382.899 28.794 381.771 27.9545 380.47 27.3668C379.083 26.6952 377.522 26.4434 375.961 26.4434H357.054C356.794 26.4434 356.621 26.5273 356.447 26.6952C356.274 26.8631 356.187 27.031 356.187 27.2829V41.3024C356.187 41.5543 356.1 41.7222 355.927 41.8901C355.753 42.058 355.58 42.1419 355.32 42.1419H352.198C351.937 42.1419 351.764 42.058 351.59 41.8901C351.417 41.7222 351.33 41.5543 351.33 41.3024V22.6657C351.33 22.4138 351.417 22.2459 351.59 22.078C351.764 21.9101 351.937 21.8262 352.198 21.8262H375.874C378.042 21.8262 380.124 22.2459 382.118 23.0015C384.113 23.841 385.847 25.0162 387.322 26.5273C388.709 28.0384 389.837 29.8014 390.531 31.7322C391.311 33.8309 391.745 35.9297 391.658 38.1123Z"
            fill="white"
          />
          <path
            d="M407.616 53.643C407.616 53.8948 407.529 54.0627 407.355 54.2306C407.182 54.3985 406.922 54.4825 406.662 54.4825H403.279C403.019 54.4825 402.759 54.3985 402.585 54.2306C402.412 54.0627 402.325 53.8948 402.325 53.643V22.6657C402.325 22.4138 402.412 22.2459 402.585 22.078C402.759 21.9101 403.019 21.8262 403.279 21.8262H406.662C406.922 21.8262 407.182 21.9101 407.355 22.078C407.529 22.2459 407.616 22.4138 407.616 22.6657V53.643Z"
            fill="white"
          />
          <path
            d="M459.044 53.643C459.044 53.8948 458.958 54.0627 458.784 54.2306C458.611 54.3985 458.437 54.4825 458.177 54.4825H455.142C454.882 54.4825 454.708 54.3985 454.535 54.2306C454.361 54.0627 454.274 53.8948 454.274 53.643V46.0036C454.274 45.7517 454.188 45.5838 454.014 45.4159C453.841 45.248 453.667 45.1641 453.407 45.1641H436.756C436.496 45.1641 436.322 45.0801 436.149 44.9123C435.975 44.7444 435.888 44.5765 435.888 44.3246V41.3864C435.888 41.1345 435.975 40.9666 436.149 40.7987C436.322 40.6308 436.496 40.5469 436.756 40.5469H453.32C453.581 40.5469 453.754 40.4629 453.928 40.295C454.101 40.1271 454.188 39.9592 454.188 39.7074V27.3668C454.188 27.115 454.101 26.9471 453.928 26.7792C453.754 26.6113 453.581 26.5273 453.32 26.5273H436.929C435.281 26.5273 433.72 26.8631 432.246 27.4508C430.772 28.0384 429.471 28.8779 428.43 29.9693C427.303 31.0606 426.435 32.3198 425.828 33.663C425.221 35.0902 424.874 36.6013 424.874 38.1963V53.643C424.874 53.8948 424.787 54.0627 424.614 54.2306C424.441 54.3985 424.267 54.4825 424.007 54.4825H420.972C420.711 54.4825 420.538 54.3985 420.364 54.2306C420.191 54.0627 420.104 53.8948 420.104 53.643V38.1963C420.104 35.342 420.885 32.4877 422.359 29.9693C423.053 28.794 424.007 27.6187 424.961 26.6952C426.002 25.6878 427.129 24.8483 428.43 24.0928C429.731 23.3373 431.032 22.8336 432.419 22.4138C433.894 21.9941 435.368 21.8262 436.929 21.8262H458.177C458.437 21.8262 458.611 21.9101 458.784 22.078C458.958 22.2459 459.044 22.4138 459.044 22.6657V53.643Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2721_5416"
          x1="40.3291"
          y1="1.63701"
          x2="40.3291"
          y2="73.3466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FD6B51" />
          <stop
            offset="0.53"
            stop-color="#FF8718"
          />
          <stop
            offset="1"
            stop-color="#FFB018"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2721_5416"
          x1="40.2428"
          y1="60.4443"
          x2="40.2428"
          y2="74.7157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8BB8FF" />
          <stop
            offset="1"
            stop-color="#4C70DF"
          />
          <stop
            offset="1"
            stop-color="#4C70DF"
          />
        </linearGradient>
        <clipPath id="clip0_2721_5416">
          <rect
            width="352.629"
            height="32.6563"
            fill="white"
            transform="translate(106.588 21.8262)"
          />
        </clipPath>
        <clipPath id="clip1_2721_5416">
          <rect
            width="352.629"
            height="32.6563"
            fill="white"
            transform="translate(106.588 21.8262)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
  export default {
    computed: {
      getScreenSize () {
        return this.$vuetify.breakpoint.width > 1000 ? true : false;
      }
    }
  }
</script>

<style scoped lang="scss">
  @media (max-width: 600px) {
    .responsive-logo {
      width: 99%;
      height: 90%;
    }
  }

  @media (min-width: 1000px) and (max-width: 1124px){
    .responsive-logo {
      width: 90%;
      height: 90%;
    }
  }
</style>
