<template>
  <v-main class="background-auth">
    <v-container
      fill-height
      justify-center
      class="container-max-width px-5 px-sm-3"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="8"
          md="6"
          lg="12"
          class="text-center mb-lg-3 mb-md-8 mt-md-10 mb-3"
        >
          <LogoComponent />
        </v-col>
        <v-col
          cols="11"
          sm="10"
          md="11"
          lg="10"
        >
          <v-row justify="center">
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="6"
              class="container-right-side rounded-all background"
            >
              <slot name="right" />
            </v-col>
          </v-row>
          <v-row 
            v-if="tab === mainView"
            class="mt-11 caption"
            justify="center" 
          >
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="6"
              class="sign-up"
            >
              <table class="table">
                <caption />
                <tr>
                  <th />
                </tr>
                <tr>
                  <td class="small-mobile">
                    <h4 class="question-option">
                      {{ items[0].content }}
                    </h4>
                  </td>
                  <td class="outline-button text-right">
                    <button
                      class="heading heading-s-small button-option"
                      @click="goToLoginOrRegister"
                    >
                      {{ signupOrLoginText }}
                    </button>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-row
            v-if="tab === mainView || (type===viewType.register)"
            class="mt-6 caption" 
            justify="center" 
          >
            <v-col
              class="terms"
              cols="10"
              sm="5"
              md="6"
              lg="5"
            >
              <p class="text text-s-large-regular white--text text-center">
                By signing up, you accept our 
                <a 
                  href="/privacy" 
                  class="secondary--text"
                >
                  Privacy Notice</a> 
                and our 
                <a 
                  href="/terms" 
                  class="secondary--text"
                >Terms of Use</a>
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <span
      id="appVersion"
      class="white--text"
    >
      {{ appVersion }}
    </span>
  </v-main>
</template>

<script>
  import LogoComponent from '@/assets/LogoComponent';
  export default {
    components: { LogoComponent },
    props: ['items', 'type', 'tab'],
    data: () => ({
      mainView: 0,
      viewType: { login: "login", register: "register" },
      enumTabs: { legalName: 1 },
    }),
    computed: {
      isMobile(){
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return true;
          default:
          return false;
        }
      },
      appVersion () {
        return `${this.$store.getters.appVersion}`;
      },
      signupOrLoginText() {
        return this.type === this.viewType.login ? 'Sign up' : 'Log in';
      },
    },
    methods: {
      goToLoginOrRegister() {
        if(this.type === this.viewType.login) {
          this.$router.push('register');
        } else {
          this.$router.push('login');
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  /* Default height */
  .container-right-side {
    flex: 0 0 auto;
    width: 605px;
  }

  .sign-up {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    height: 76px;
    padding-top: 12px;
  }

  .button-option {
    padding: 8px 24px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    color: #FFFFFF;
  }

  .table {
    width: 100%;
  }

  .outline-button {
    text-align: -webkit-right;
  }

  .question-option {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  /* Tablets */
  @media (min-width: 601px) and (max-width: 811px) {
    .container-right-side {
      flex: 0 0 auto;
      width: 435px;
    }
  }
  /* Small Mobile */
  @media (min-width: 320px) { 
    .small-mobile {
      width: 40%;
    }

    .terms {
      padding: 0 10px;
    }
  }
  
  /* Mobile */
  @media (min-width: 375px) {
    .small-mobile {
      width: 64%;
    }
    .container-max-width {
      padding: 12px 20px 15px 20px;
    }

    .question-option {
      font-size: 15px;
    }
    .terms {
      padding: 0 32px;
    }
  }
  /* Large desktops and up */
  @media (min-width: 768px) { 
    .terms {
      padding: 0 28px;
    }
  }

  @media (min-width: 960px) {
    .terms {
      padding: 0;
    }
  }
  /* Large desktops but with odd ratios */
  @media (min-width: 960px) and (max-height: 700px) and (orientation:landscape) {
    .container-right-side {
      flex: 0 0 auto;
      width: 42vw;
    }
    .container-max-width {
      max-width: 1250px;
    }
  }
  .rounded-all {
    border-radius: 11px;
  }
  #appVersion {
    position: absolute;
    bottom: 5px;
    left: 10px;
    font-size: 11px;
  }
</style>
